<template>
  <div
    ref="rootRef"
    class="max-lg:container-extend relative"
  >
    <div
      v-if="!scrollZoomAllowed"
      class="absolute inset-0 z-[9999] flex items-center justify-center bg-black/50 text-xl text-white transition-opacity duration-300"
      :class="{
        'opacity-0': !scrolling,
      }"
      @wheel="scrolling = true"
      @click="allowScrollZoom"
    >
      Klik hier om te zoomen door te scrollen
    </div>

    <LMap
      ref="mapRef"
      :zoom="zoom"
      :min-zoom="minZoom"
      :max-zoom="maxZoom"
      :center="center ?? [52.10093,5.6436748]"
      :use-global-leaflet="true"
      class="relative min-h-[500px] max-lg:!max-h-screen"
      :class="classMap"
      @ready="onMapReady"
      @update:bounds="emitUpdateBounds"
    >
      <LTileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution="&amp;copy; <a href=&quot;https://www.openstreetmap.org/&quot;>OpenStreetMap</a> contributors"
        layer-type="base"
        name="OpenStreetMap"
      />
      <LMarkerClusterGroup
        :max-cluster-radius="50"
        @ready="delay(fitBounds, 200, $event)"
      >
        <slot
          v-for="marker in markers"
          :marker="marker"
        />
      </LMarkerClusterGroup>
    </LMap>
  </div>
</template>

<script setup lang="ts" generic="T extends {latitude: number, longitude: number}">
import type {Map} from 'leaflet';
import L from 'leaflet';
import {delay} from 'lodash-es';
import {LMarkerClusterGroup} from 'vue-leaflet-markercluster';

const props = withDefaults(defineProps<{
  classMap?: string | (string | Record<string, boolean>)[] | Record<string, boolean>
  center?: {
    lat: number
    lng: number
  }
  markers: T[]
  centerBounds?: boolean
  zoom?: number
  mapType?: string
  minZoom?: number
  maxZoom?: number
}>(), {
  center: undefined,
  mapType: 'terrain',
  zoom: 12,
  minZoom: 10,
  maxZoom: 15,
});

defineSlots<{
  default(props: { marker: T }): void
}>();

const emit = defineEmits<{
  'update:bounds': [L.LatLngBounds]
}>();

const mapInstance = ref<Map>();

provide('leaflet.map', mapInstance);

const emitUpdateBounds = useDebounceFn((bounds: L.LatLngBounds) => {
  emit('update:bounds', bounds);
}, 500);

function onMapReady(map: Map) {
  mapInstance.value = map;

  map.scrollWheelZoom.disable();
  map.attributionControl.setPrefix('<a href="https://leafletjs.com/">Leaflet</a>');
}

function fitBounds() {
  const map = mapInstance.value!;

  if (!props.centerBounds || !props.markers.length) {
    return;
  }

  const currentBounds = map.getBounds();

  if (!currentBounds || !currentBounds.isValid()) {
    return;
  }

  const bounds = L.latLngBounds(currentBounds.getSouthWest(), currentBounds.getNorthEast());

  map.eachLayer((layer: L.Layer) => {
    if (layer instanceof L.Marker) {
      bounds.extend(layer.getLatLng());
    }
  });

  if (bounds.isValid()) {
    map.fitBounds(bounds);
  }
}

const scrolling = ref(false);
const scrollZoomAllowed = ref(false);

function allowScrollZoom() {
  scrolling.value = false;
  mapInstance.value?.scrollWheelZoom.enable();
  scrollZoomAllowed.value = true;

  window.removeEventListener('wheel', onScroll);
}

const rootRef = ref<HTMLElement>();
let scrollTimeout: NodeJS.Timer;

watch(scrolling, (isScrolling) => {
  if (scrollTimeout) {
    clearTimeout(scrollTimeout);
  }

  if (isScrolling) {
    scrollTimeout = setTimeout(() => {
      scrolling.value = false;
    }, 1000);
  }
});

const onScroll = (e: WheelEvent) => {
  if (rootRef.value?.contains(e.target as HTMLElement)) {
    scrolling.value = true;
  }
};

onMounted(() => {
  window.addEventListener('wheel', onScroll);
});

onUnmounted(() => {
  window.removeEventListener('wheel', onScroll);
});
</script>
